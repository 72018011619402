.logo-module__cls1__Go-q4 {
	fill: #226cfd;
}

.logo-module__cls1__Go-q4, .logo-module__cls2__YAWbD {
	stroke-width: 0px;
}

.logo-module__cls2__YAWbD {
	fill: #1f2937;
}

@media (prefers-color-scheme: dark) {
	.logo-module__cls1__Go-q4{fill:#226cfd;}
	.logo-module__cls2__YAWbD{fill:#f3f4f6;}
}

.logo-module__st0__CMO-a{fill:#1F2937;}
.logo-module__st1__SQMUN{fill:#226CFD;}