.error-boundary-module__description__T7Kl- {
	max-width: calc(31.25rem * var(--mantine-scale));
	margin: auto;
	margin-top: var(--mantine-spacing-xl);
	margin-bottom: var(--mantine-spacing-xl);
}

.error-boundary-module__title__BkcqW {
	text-align: center;
	font-weight: 900;
	font-size: calc(2.5rem * var(--mantine-scale));
}

.error-boundary-module__button__ACSt2 {
	margin-top: var(--mantine-spacing-xl);
}
