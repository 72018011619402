.header-module__header__EftWT {
	height: calc(3.5rem * var(--mantine-scale));
	margin-bottom: calc(2.5rem * var(--mantine-scale));
	background-color: var(--mantine-color-body);
	border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
}
	[data-mantine-color-scheme='dark'] .header-module__header__EftWT {
	border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}

.header-module__inner__6tKjN {
	height: calc(3.5rem * var(--mantine-scale));
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-module__link__gPS68 {
	display: block;
	line-height: 1;
	padding: calc(0.5rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale));
	border-radius: var(--mantine-radius-md);
	text-decoration: none;
	color: var(--mantine-color-gray-7);
}

[data-mantine-color-scheme='dark'] .header-module__link__gPS68 {
	color: var(--mantine-color-dark-0);
}

.header-module__link__gPS68 {
	font-size: var(--mantine-font-size-sm);
	font-weight: 500;
}

@media (hover: hover) {
	.header-module__link__gPS68:hover {
		background-color: var(--mantine-color-gray-0);
	}
		[data-mantine-color-scheme='dark'] .header-module__link__gPS68:hover {
		background-color: var(--mantine-color-dark-6);
	}
}

@media (hover: none) {
	.header-module__link__gPS68:active {
		background-color: var(--mantine-color-gray-0);
	}
		[data-mantine-color-scheme='dark'] .header-module__link__gPS68:active {
		background-color: var(--mantine-color-dark-6);
	}
}

[data-mantine-color-scheme] .header-module__link__gPS68[data-active] {
		background-color: var(--mantine-color-blue-filled);
		color: var(--mantine-color-white);
	}
